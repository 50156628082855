import axios from "axios";
import ability from '../libs/acl/ability';
import { API_URL } from "../../conf/environment";

export default {
  register(email, password) {
    const user = { email, password };
    return axios.post(API_URL + "/regiser", user);
  },
  async login(email, password) {
    const user = { correoElectronico:email, password };
    let res= await axios.post(API_URL + "auth/login", user).then(res=>res.data);
    localStorage.setItem('accessToken', res.token);
    localStorage.setItem('userData', JSON.stringify(res.user));
    ability.update(res.user.permisos);
    return res;
  }
}